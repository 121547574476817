.col-form {
  margin-bottom: 15px;
}
.normal-form label {
  font-weight: 650;
}

.soft-labels label{
  font-weight: 500;
}
.form-container {
    max-width: 800px; /* Máximo ancho */
    margin: auto; /* Centrar horizontalmente */
}
/* Hace que el modal tenga un fondo transparente */
.transparent-modal .modal-content {
  background-color: transparent;  /* Modal transparente */
  border: none;  /* Elimina los bordes */
}

/* El div que envuelve todo después de Draggable tendrá el fondo blanco */
.modal-content-white {
  background-color: white;  /* Fondo blanco */
  border-radius: 8px;  /* Bordes redondeados (opcional) */
}
.row-label-strong label {
  font-weight: bold;
}

.beneficiario-dropdown {
  position: absolute; /* Permite posicionar el dropdown fuera del flujo normal */
  z-index: 9999; /* Un valor alto para asegurar que se superponga a otros elementos */
  background-color: white; /* Asegura que el fondo sea blanco y se vea bien */
  width: 80%; /* Ajusta el ancho del dropdown al 100% del input */
  border: 1px solid #ccc; /* Borde para diferenciar el dropdown */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra para mejorar la visibilidad */
  max-height: 200px; /* Limita la altura del dropdown */
  overflow-y: auto; /* Hace que se pueda desplazar si hay demasiados elementos */
  margin-top: 2px; /* Espacio para no pegarse al campo de entrada */
}

.beneficiario-item {
  padding: 8px; /* Espaciado para cada elemento */
  cursor: pointer; /* Cambia el cursor para indicar que se puede hacer clic */
}

.beneficiario-item:hover {
  background-color: #f0f0f0; /* Resalta el ítem cuando se pasa el ratón por encima */
}

.number-input {
  width: 120px;
}

.banco-input {
  width: 120px;
}
@media (min-width: 900px) {
  .number-input {
    width: 150px;
  }
  .banco-input {
    width: 230px;
  }
}